body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.row{
  margin: 0 !important;
}
.Toastify__toast {
  border-radius: 6px !important;
  padding: 1em !important;
}
.card{
  border: 1px solid #eee;
}
svg path{
  pointer-events: none;
}
btn-link, .btn-link:hover, .btn-link:focus{
  text-decoration: none !important;
  box-shadow: none !important;
}
.text-sm{
  font-size: 14px;
}
.text-md{
  font-size: 16px;
}
.text-lg{
  font-size: 18px;
}
.katex{
  font-size: 1.2em!important;
}
.text-bold{
  font-weight: 600;
}
.text-gray{
  color: #777;
}
.text-black{
  color: #000;
}
.pointer-events-none{
  pointer-events: none;
}
.cursor-pointer{
  cursor: pointer;
}
.rotate{
  animation: rotation 1s infinite linear;
}
.body-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blink{
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.content-body{
  padding: 1em;
}

.css-2b097c-container{
  z-index: 20;
}

.search-input {
  border: 1px solid #ccc;
  margin: 5px;
  padding: 4px 6px;
  border-radius: 3px;
  font-size: 14px;
}
.search-input:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

input[type='checkbox'] {
  -webkit-appearance:none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 2px;
  border: 1px solid #555 !important;
  font-size: 12px;
}
input[type='checkbox']:checked {
  background:#39953c;
  border: 1px solid #39953c;
  display: flex;
  justify-content: center;
  color: #fff;
  line-height: 1.5;
}
input[type="checkbox"]:checked:after {
  content: "\2714";
}
input[type="checkbox"]:focus {
  outline: none;
}