.sidebar-link {
    color: #fff;
    display: block;
    padding: 0.8em 1.8em;
    text-decoration: none;
    font-size: 15px;
    margin: 4px 0;
    border-left: 4px solid transparent;
}
.sidebar-link.active {
    background: #00283f;
    color: #FFEB3B;
    text-decoration: none;
    border-left-color: #FFEB3B;
    font-weight: 600;
}
.sidebar-link:hover {
    text-decoration: none;
    color: #FFEB3B;
}
